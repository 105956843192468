import * as React from "react"

import Layout from "../components/layout"
import ContactFormBasic from "../components/ContactFormBasic"

const NetlifyFormProcessingPage = () => (
  <Layout>
    <h1>Netlify Form Things</h1>
    <ContactFormBasic></ContactFormBasic>
  </Layout>
)

export default NetlifyFormProcessingPage
