import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
  border: 1px solid lightblue;
  width: 100%;
  height: auto;

  section {
    border: 1px solid lightyellow;
  }

  span {
    border: 1px solid lightpink;
    display: inline-block;
  }

  input,
  label {
    margin-right: 5px;
  }
`

const ContactFormBasic = () => (
  <Container>
    <p>Contact Form Basic</p>
    <form
      name="Contact Form"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      action="/404"
    >
      <input type="hidden" name="form-name" value="Contact Form" />

      <p
        style={{
          position: `absolute`,
          overFlow: `hidden`,
          clip: "rect(0 0 0 0)",
          height: "1px",
          width: "1px",
          margin: "-1px",
        }}
      >
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      {/* <span>
        <label for=""></label>
        <input type="" id="" name="" required></input>
      </span> */}

      {/* Contact Information:
        first name
        last name
        pronoun
        email address
        phone number */}

      <section className="contact-info">
        <span>
          <label for="firstName">First Name*:</label>
          <input type="text" id="firstName" name="First Name" required></input>
        </span>

        <span>
          <label for="lastName">Last Name*:</label>
          <input type="text" id="lastName" name="Last Name" required></input>
        </span>

        <br></br>

        <span>
          <label for="pronoun">Preferred Pronoun:</label>
          <select id="pronoun" name="Preferred Pronoun">
            <option value="she">She/Her</option>
            <option value="he">He/Him</option>
            <option value="they">They/Them</option>
            <option value="na">Prefer Not To Say</option>
          </select>
        </span>

        <br></br>

        <span>
          <label for="email">Email*:</label>
          <input type="email" id="email" name="Email" required></input>
        </span>

        <br></br>

        <span>
          <label for="phone">Phone Number*:</label>
          <input type="tel" id="phone" name="Phone Number" required></input>
        </span>
      </section>

      {/* Tell Me More:
        business name
        link to existing website or shop
        business stage/I'm looking to: start or level up
        what problem does your business solve? / what does your business offer? / please describe your goods/services
        what inspired you to start your business?
        social media links, facebook, instagram */}

      <section className="tell-me-more">
        <span>
          <label for="busName">Business Name*:</label>
          <input type="text" id="busName" name="Business Name" required></input>
        </span>

        <br></br>

        <span>
          <label for="url">
            Link to existing website/shop (if applicable):
          </label>
          <input type="url" id="url" name="Website"></input>
        </span>

        <br></br>

        <span>
          <p>I'm looking to*:</p>
          <input
            type="radio"
            id="startBus"
            name="Looking To"
            value="Start"
            required
          ></input>
          <label for="startBus">Start my business</label>
          <input
            type="radio"
            id="levelUpBus"
            name="Looking To"
            value="Level Up"
            required
          ></input>
          <label for="levelUpBus">Level up my business</label>
        </span>

        <br></br>

        <span>
          <label for="productsServices">
            Please describe your products and/or services*:
          </label>
          <textarea
            id="productsServices"
            name="Products/Services"
            rows="5"
            cols="30"
            required
          ></textarea>
        </span>

        <br></br>

        <span>
          <label for="inspiration">
            What inspired you to start your business*?
          </label>
          <textarea
            id="inspiration"
            name="Inspiration"
            rows="5"
            cols="30"
            required
          ></textarea>
        </span>

        <br></br>

        <span>
          <label for="facebook">Facebook URL (if applicable):</label>
          <input type="url" id="facebook" name="Facebook URL"></input>
        </span>

        <br></br>

        <span>
          <label for="instagram">Instagram URL (if applicable):</label>
          <input type="url" id="instagram" name="Instagram URL"></input>
        </span>
      </section>

      {/* How Can I Help:
        I need help with my: website / Shopify store
        what business goals can I help you achieve with a new website/store?
        your budget / dropdown to select a range
        your timeline / do you have a target date to launch a new website / store?
        how did you hear about The CC Shop */}

      <section className="how-can-i-help">
        <span>
          <p>I would like help with my*:</p>
          <input
            type="radio"
            id="website"
            name="Help With"
            value="Website"
            required
          ></input>
          <label for="website">Website</label>
          <input
            type="radio"
            id="shopify"
            name="Help With"
            value="Shopify"
            required
          ></input>
          <label for="shopify">Shopify Store</label>
        </span>

        <br></br>

        <span>
          <label for="goals">
            What goal(s) are you looking to achieve with a new website or
            store?*:
          </label>
          <textarea
            id="goals"
            name="Goals"
            rows="5"
            cols="30"
            required
          ></textarea>
        </span>

        <br></br>

        <span>
          <label for="budget">Your budget*:</label>
          <input type="number" id="budget" name="Budget" required></input>
        </span>

        <br></br>

        <span>
          <p>Do you have a target date to launch a new website/store?*:</p>
          <input
            type="radio"
            id="flexDate"
            name="Has Target Date"
            value="YesFlexible"
            required
          ></input>
          <label for="flexDate">Yes, but it's flexible</label>
          <input
            type="radio"
            id="yesDate"
            name="Has Target Date"
            value="Yes"
            required
          ></input>
          <label for="yesDate">Yes</label>
          <input
            type="radio"
            id="noDate"
            name="Has Target Date"
            value="No"
            required
          ></input>
          <label for="noDate">No</label>
        </span>

        <br></br>

        {/* if responded yes or yes flexible to above question */}
        <span>
          <label for="date">Target Date (if applicable):</label>
          <input type="date" id="date" name="Target Date"></input>
        </span>

        <br></br>

        <span>
          <p>How did you hear about The CC Shop? (check all that apply)*:</p>
          <input
            type="checkbox"
            id="personalRef"
            name="Personal Referral"
            value="Personal Referral"
          ></input>
          <label for="personalRef">Personal Referral</label>
          <input
            type="checkbox"
            id="ig"
            name="Instagram"
            value="Instagram"
          ></input>
          <label for="ig">Instagram</label>
          <input
            type="checkbox"
            id="fb"
            name="Facebook"
            value="Facebook"
          ></input>
          <label for="fb">Facebook</label>
          <input type="checkbox" id="ads" name="Ads" value="Ads"></input>
          <label for="ads">Ads</label>
          <input
            type="checkbox"
            id="google"
            name="Google"
            value="Google"
          ></input>
          <label for="google">Google</label>
        </span>
      </section>

      <input type="submit" value="Submit"></input>
    </form>
  </Container>
)

export default ContactFormBasic
